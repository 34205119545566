import { SupabaseClient } from '@supabase/supabase-js'

import { Database, TablesInsert } from '@/supabase'

interface CreateRecordArgs {
  client: SupabaseClient<Database>
  values: TablesInsert<'records'>
}

const createRecord = ({ client, values }: CreateRecordArgs) => {
  return client.from('records').insert(values)
}

export default createRecord
