import { SupabaseClient } from '@supabase/supabase-js'

import { Database } from '@/supabase'

interface GetActivitiesByRecordIdArgs {
  recordId: string
  client: SupabaseClient<Database>
}

const getActivitiesByRecordId = async ({
  recordId,
  client,
}: GetActivitiesByRecordIdArgs) => {
  const { data, error } = await client
    .from('activity')
    .select('*')
    .order('created_at', { ascending: false })
    .eq('record_id', recordId)
  if (error) {
    throw error
  }
  return data
}

export default getActivitiesByRecordId
