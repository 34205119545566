import { SupabaseClient } from '@supabase/supabase-js'

import { Database } from '@/supabase'

interface GetRecordsCountArgs {
  client: SupabaseClient<Database>
}

const getRecordsCount = async ({ client }: GetRecordsCountArgs) => {
  const { count, error } = await client
    .from('records')
    .select('*', { count: 'exact', head: true })
  if (error) {
    throw error
  }
  return count
}

export default getRecordsCount
