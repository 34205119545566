import { SupabaseClient } from '@supabase/supabase-js'

import { Database } from '@/supabase'

interface UpdateActivityArgs {
  client: SupabaseClient<Database>
  id: string
  recordId: string
  completed: boolean
}
const updateActivity = async ({
  id,
  client,
  completed,
}: UpdateActivityArgs) => {
  return client.from('activity').update({ completed }).eq('id', id)
}

export default updateActivity
