import { useQuery } from '@tanstack/react-query'

import { browserClient } from '@/supabase'

import { getRecordsCount } from '../../actions'
import { QueryKeys } from '../../query-keys'

const useGetRecordsCount = () => {
  const supabase = browserClient()
  return useQuery({
    queryKey: [QueryKeys.GetRecordsCount],
    queryFn: async () => {
      return await getRecordsCount({
        client: supabase,
      })
    },
  })
}

export default useGetRecordsCount
