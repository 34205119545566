import { SupabaseClient } from '@supabase/supabase-js'

import { Database } from '@/supabase'

const getAllCategoryVisibility = async (client: SupabaseClient<Database>) => {
  const { data, error } = await client.from('category_visibility').select('*')
  if (error) {
    throw error
  }
  return data
}

export default getAllCategoryVisibility
