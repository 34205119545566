import { useMutation, useQueryClient } from '@tanstack/react-query'
import { formatISO } from 'date-fns'

import { createInsight } from '@/actions'
import { FormRecordValues } from '@/store/type'
import { browserClient,LifeCategory, Tables } from '@/supabase'

import { createRecord } from '../../actions'
import { QueryKeys } from '../../query-keys'

const useCreateRecord = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  const supabase = browserClient()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      values,
      visibleFields,
      previousRecord,
      previousActivities,
    }: {
      values: FormRecordValues
      visibleFields: LifeCategory[]
      previousRecord?: FormRecordValues
      previousActivities?: Tables<'activity'>[]
    }) => {
      const insight = await createInsight({
        currentRecord: values,
        previousRecord,
        previousActivities,
        categoryVisibility: visibleFields,
      })
      const createdAt = formatISO(new Date())
      const formattedValues = Object.entries(values).reduce(
        (acc, [key, value]) => {
          const key_ = key as LifeCategory
          if (!visibleFields.includes(key_)) return acc
          acc[key_] = Math.round(value)
          return acc
        },
        {} as FormRecordValues,
      )
      await createRecord({
        client: supabase,
        values: {
          insight,
          created_at: createdAt,
          ...formattedValues,
        },
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.History],
      })
      onSuccess?.()
    },
  })
}

export default useCreateRecord
