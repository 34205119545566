import { useQuery } from '@tanstack/react-query'

import { browserClient } from '@/supabase'

import { getRecordById } from '../../actions'
import { QueryKeys } from '../../query-keys'

const useGetRecordById = (id: string) => {
  const supabase = browserClient()
  return useQuery({
    queryKey: [QueryKeys.GetRecordById, { id }],
    queryFn: async () => {
      return await getRecordById({
        client: supabase,
        recordId: id,
      })
    },
  })
}

export default useGetRecordById
