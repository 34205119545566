import { useMutation, useQueryClient } from '@tanstack/react-query'

import { createInsight } from '@/actions'
import { FormRecordValues } from '@/store/type'
import { browserClient,LifeCategory } from '@/supabase'

import { RecordsQueryKeys } from '../..'
import { updateRecord } from '../../actions'

const useUpdateRecord = ({
  onSuccess,
}: { onSuccess?: (recordId: string) => void } = {}) => {
  const supabase = browserClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      recordId,
      values,
      visibleFields,
      previousRecord,
    }: {
      recordId: string
      values: FormRecordValues
      visibleFields: LifeCategory[]
      previousRecord?: FormRecordValues
    }) => {
      const insight = await createInsight({
        currentRecord: values,
        previousRecord,
        previousActivities: [],
        categoryVisibility: visibleFields,
      })
      const formattedValues = Object.entries(values).reduce(
        (acc, [key, value]) => {
          const key_ = key as LifeCategory
          if (!visibleFields.includes(key_)) return acc
          acc[key_] = Math.round(value)
          return acc
        },
        {} as FormRecordValues,
      )
      await updateRecord({
        recordId,
        client: supabase,
        values: {
          insight,
          ...formattedValues,
        },
      })
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [RecordsQueryKeys.History],
      })
      queryClient.invalidateQueries({
        queryKey: [RecordsQueryKeys.GetLastCoupleRecords],
      })
      queryClient.invalidateQueries({
        queryKey: [RecordsQueryKeys.GetRecordById, { id: variables.recordId }],
      })
      onSuccess?.(variables.recordId)
    },
  })
}

export default useUpdateRecord
