import { SupabaseClient } from '@supabase/supabase-js'

import { Database } from '@/supabase'

interface GetRecordByIdArgs {
  client: SupabaseClient<Database>
  recordId: string
}

const getRecordById = async ({ client, recordId }: GetRecordByIdArgs) => {
  const { data, error } = await client
    .from('records')
    .select('*')
    .eq('id', recordId)
    .limit(1)
    .single()
  if (error) {
    console.log(error)
    return null
  }
  return data
}

export default getRecordById
