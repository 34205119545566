import { SupabaseClient } from '@supabase/supabase-js'

import { Database, TablesUpdate } from '@/supabase'

interface UpdateRecordArgs {
  client: SupabaseClient<Database>
  recordId: string
  values: TablesUpdate<'records'>
}

const updateRecord = ({ client, recordId, values }: UpdateRecordArgs) => {
  return client.from('records').update(values).eq('id', recordId)
}

export default updateRecord
