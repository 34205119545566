import { SupabaseClient } from '@supabase/supabase-js'

import { Database } from '@/supabase'

interface DeleteActivityArgs {
  activityId: string
  client: SupabaseClient<Database>
}
const deleteActivity = ({ client, activityId }: DeleteActivityArgs) => {
  return client.from('activity').delete().eq('id', activityId)
}

export default deleteActivity
