import { SupabaseClient } from '@supabase/supabase-js'

import { Database } from '@/supabase'

interface GetLastCoupleRecordsArgs {
  client: SupabaseClient<Database>
  limit?: 1 | 2
}

const getLastCoupleRecords = async ({
  client,
  limit = 2,
}: GetLastCoupleRecordsArgs) => {
  const { data, error } = await client
    .from('records')
    .select('*')
    .limit(limit)
    .order('created_at', { ascending: false })
  if (error) {
    throw error
  }
  return data
}

export default getLastCoupleRecords
