import { useQuery } from '@tanstack/react-query'

import { browserClient } from '@/supabase'

import { getActivitiesByRecordId } from '../..'
import { QueryKeys } from '../../query_keys'

const useActivitiesByRecordId = (recordId: string) => {
  const supabase = browserClient()
  return useQuery({
    queryKey: [QueryKeys.GetAllActivitiesByRecordId, { recordId }],
    queryFn: async () => {
      return await getActivitiesByRecordId({
        client: supabase,
        recordId,
      })
    },
    enabled: !!recordId,
  })
}

export default useActivitiesByRecordId
