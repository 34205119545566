import { useMutation, useQueryClient } from '@tanstack/react-query'

import { browserClient,Tables } from '@/supabase'

import { ActivitiesQueryKeys } from '../..'
import { updateActivity } from '../../actions'

type PartialActivity = Pick<
  Tables<'activity'>,
  'record_id' | 'completed' | 'id'
>

const useUpdateActivity = () => {
  const queryClient = useQueryClient()
  const client = browserClient()
  return useMutation({
    mutationFn: (activity: PartialActivity) => {
      return updateActivity({
        client,
        completed: activity.completed ?? false,
        id: activity.id,
        recordId: activity.record_id ?? '',
      })
    },
    onMutate: async (activity) => {
      // Snapshot the previous value
      const previousActivity = queryClient.getQueryData([
        ActivitiesQueryKeys.GetAllActivitiesByRecordId,
        { recordId: activity.record_id },
      ]) as Tables<'activity'>[]

      // Optimistically update to the new value
      queryClient.setQueryData<Tables<'activity'>[]>(
        [
          ActivitiesQueryKeys.GetAllActivitiesByRecordId,
          { recordId: activity.record_id },
        ],
        (old) => {
          if (old) {
            return old.map((a) => {
              if (a.id === activity.id) {
                return { ...a, completed: activity.completed }
              }
              return a
            })
          }
          return old
        },
      )

      return { previousActivity }
    },
    onError: (_err, variables, context) => {
      // Roll back to the previous value
      queryClient.setQueryData(
        [
          ActivitiesQueryKeys.GetAllActivitiesByRecordId,
          { recordId: variables.record_id },
        ],
        context?.previousActivity,
      )
    },
    onSettled: (_response, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          ActivitiesQueryKeys.GetAllActivitiesByRecordId,
          { recordId: variables.record_id },
        ],
      })
    },
  })
}

export default useUpdateActivity
