import { useQuery } from '@tanstack/react-query'

import { browserClient } from '@/supabase'

import getAllCategoryVisibility from '../../actions/get-all-category-visibility'
import { QueryKeys } from '../../query-keys'

const useAllCategoryVisibility = () => {
  const supabase = browserClient()
  return useQuery({
    queryKey: [QueryKeys.GetAllCategoryVisibility],
    queryFn: async () => {
      return await getAllCategoryVisibility(supabase)
    },
  })
}

export default useAllCategoryVisibility
