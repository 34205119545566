import { useInfiniteQuery } from '@tanstack/react-query'

import { browserClient } from '@/supabase'

import { QueryKeys } from '../../query-keys'

const LIMIT = 15

export const getPagination = (page: number, size: number) => {
  const limit = size ? +size : 3
  const from = page ? page * limit : 0
  const to = page ? from + size : size

  return { from, to }
}

type PaginationParams = {
  from: number
  to: number
}

const useHistoryRecords = () => {
  const client = browserClient()

  const getHistoryRecords = async (params: PaginationParams) => {
    const { data, error } = await client
      .from('records')
      .select('*')
      .order('created_at', { ascending: false })
      .range(params.from, params.to)
    if (error) {
      throw error
    }
    return data
  }

  return useInfiniteQuery({
    queryKey: [QueryKeys.History] as const,
    initialPageParam: 0,
    queryFn: async ({ pageParam }) => {
      const paginationParams = getPagination(pageParam, LIMIT)
      return getHistoryRecords(paginationParams)
    },
    getNextPageParam: (lastPage, allPages) => {
      const lastPageLength = lastPage.length
      const allPagesLength = allPages.flat().length
      if (lastPageLength < LIMIT || allPagesLength < LIMIT) {
        return undefined
      }
      return allPagesLength / LIMIT + 1
    },
  })
}

export default useHistoryRecords
