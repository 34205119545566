import { useMutation, useQueryClient } from '@tanstack/react-query'

import { browserClient } from '@/supabase'

import { QueryKeys } from '../../query-keys'

const useDeleteRecord = ({ onSuccess }: { onSuccess?: () => void }) => {
  const supabase = browserClient()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (recordId: string) => {
      await supabase.from('records').delete().eq('id', recordId)
    },
    onSuccess: () => {
      onSuccess?.()
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GetRecordsCount],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GetLastCoupleRecords],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.History],
      })
    },
  })
}

export default useDeleteRecord
