import { useMutation, useQueryClient } from '@tanstack/react-query'

import { browserClient } from '@/supabase'
import { TablesInsert } from '@/supabase/database-generated.types'

import { ActivitiesQueryKeys, createActivity } from '../..'

const useCreateActivity = () => {
  const queryClient = useQueryClient()
  const supabase = browserClient()
  return useMutation({
    mutationFn: async (activity: TablesInsert<'activity'>) =>
      createActivity({ client: supabase, activity }),
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          ActivitiesQueryKeys.GetAllActivitiesByRecordId,
          { recordId: variables.record_id },
        ],
      })
    },
  })
}

export default useCreateActivity
