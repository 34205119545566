import { SupabaseClient } from '@supabase/supabase-js'

import { Database, TablesInsert } from '@/supabase/database-generated.types'

interface CreateActivityArgs {
  client: SupabaseClient<Database>
  activity: TablesInsert<'activity'>
}

const createActivity = ({ client, activity }: CreateActivityArgs) => {
  return client.from('activity').insert(activity)
}

export default createActivity
