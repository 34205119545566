import { useMutation, useQueryClient } from '@tanstack/react-query'

import { browserClient,Tables } from '@/supabase'

import { ActivitiesQueryKeys } from '../..'
import deleteActivity from '../../actions/delete-activity'

const useDeleteActivity = () => {
  const queryClient = useQueryClient()
  const supabase = browserClient()
  return useMutation({
    mutationFn: async ({
      activityId,
    }: {
      activityId: string
      recordId: string
    }) => {
      await deleteActivity({ activityId, client: supabase })
    },
    onMutate: async ({ activityId, recordId }) => {
      const previousActivities = queryClient.getQueryData([
        ActivitiesQueryKeys.GetAllActivitiesByRecordId,
        { recordId },
      ])

      queryClient.setQueryData<Tables<'activity'>[]>(
        [ActivitiesQueryKeys.GetAllActivitiesByRecordId, { recordId }],
        (old) => {
          if (old) {
            return old.filter((a) => a.id !== activityId)
          }
          return old
        },
      )

      return { previousActivities }
    },
    onError: (_err, variables, context) => {
      queryClient.setQueryData(
        [
          ActivitiesQueryKeys.GetAllActivitiesByRecordId,
          { recordId: variables.recordId },
        ],
        context?.previousActivities,
      )
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          ActivitiesQueryKeys.GetAllActivitiesByRecordId,
          { recordId: variables.recordId },
        ],
      })
    },
  })
}

export default useDeleteActivity
