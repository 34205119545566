import { useMutation, useQueryClient } from '@tanstack/react-query'

import { browserClient,LifeCategory, Tables } from '@/supabase'

import getAllCategoryVisibility from '../../actions/get-all-category-visibility'
import { QueryKeys } from '../../query-keys'

type UpdateCategoryVisibilityArgs = {
  category: LifeCategory
  visible: boolean
  id?: string
  userId?: string
}

const useUpsertCategoryVisibility = () => {
  const supabase = browserClient()
  const queryClient = useQueryClient()
  const upsertCategoryVisibility = async ({
    category,
    visible,
    id,
  }: UpdateCategoryVisibilityArgs) => {
    if (id) {
      return await supabase
        .from('category_visibility')
        .update({ visible })
        .eq('id', id)
    } else {
      return await supabase.from('category_visibility').insert({
        category,
        visible,
      })
    }
  }
  return useMutation({
    mutationFn: upsertCategoryVisibility,
    onMutate: async (categoryVisibility) => {
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.GetAllCategoryVisibility],
      })

      // Snapshot the previous value
      const previousCategoryVisibility = queryClient.getQueryData([
        QueryKeys.GetAllCategoryVisibility,
      ])

      // Optimistically update to the new value
      if (!categoryVisibility.id) {
        queryClient.setQueryData<Tables<'category_visibility'>[]>(
          [QueryKeys.GetAllCategoryVisibility],
          (old) => {
            const newValue = {
              user_id: '',
              category: categoryVisibility.category,
              visible: categoryVisibility.visible,
              created_at: '',
              id: '',
            }
            if (old) {
              return [...old, newValue]
            }
            return old
          },
        )
      } else {
        queryClient.setQueryData<Tables<'category_visibility'>[]>(
          [QueryKeys.GetAllCategoryVisibility],
          (old) => {
            if (old) {
              const newCategoryVisibility = old.find(
                (category) => category.id === categoryVisibility.id,
              )
              if (newCategoryVisibility) {
                newCategoryVisibility.visible = categoryVisibility.visible
                return old
              }
            }
            return old
          },
        )
      }

      // Return a context object with the snapshotted value
      return { previousCategoryVisibility }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newCategoryVisibility, context) => {
      queryClient.setQueryData(
        [getAllCategoryVisibility],
        context?.previousCategoryVisibility,
      )
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GetAllCategoryVisibility],
      })
    },
  })
}

export default useUpsertCategoryVisibility
