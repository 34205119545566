import { useQuery } from '@tanstack/react-query'

import { browserClient } from '@/supabase'

import { getLastCoupleRecords } from '../../actions'
import { QueryKeys } from '../../query-keys'

interface UseGetLastCoupleRecordsProps {
  limit?: 1 | 2
}

const useGetLastCoupleRecords = (props?: UseGetLastCoupleRecordsProps) => {
  const limit = props?.limit ?? 2
  const supabase = browserClient()
  return useQuery({
    queryKey: [QueryKeys.GetLastCoupleRecords, { limit }],
    queryFn: async () => {
      return await getLastCoupleRecords({
        client: supabase,
        limit,
      })
    },
  })
}

export default useGetLastCoupleRecords
